import React from "react";
import { Trans, t } from "@lingui/macro";
import { Box, Button, Typography } from "@material-ui/core";
import CheckIcon from "../../icons/success-circle-icon";
import OwnerModal from "../owner-modal";
import theme from "../../theme";

const ActionResultModal = ({ isOpen, status, successMessage, errorMessage, onClose }) => {
  return (
    <OwnerModal open={isOpen} onClose={onClose} width={450}>
      {status === "success" && (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" alignSelf="center" width={400}>
          <CheckIcon fill={theme.palette.successGreen} width={56} height={56} />
          <Box mb={3}>
            <Typography variant="subtitle1" style={{ color: theme.palette.successGreen }}>
              {successMessage}
            </Typography>
          </Box>
          <Button variant="contained" color="primary" style={{ width: 150 }} onClick={onClose}>
            <Trans>Aceptar</Trans>
          </Button>
        </Box>
      )}

      {status === "error" && (
        <Box display="flex" flexDirection="column">
          <Box mb={3} width="100%" style={{ wordBreak: "break-word" }}>
            <Typography variant="subtitle1" style={{ color: theme.palette.error.main }}>
              <Trans>Oops! Lo sentimos, hubo un inconveniente</Trans> {errorMessage}
            </Typography>
          </Box>
          <Button variant="text" color="primary" onClick={onClose}>
            <Trans>Salir</Trans>
          </Button>
        </Box>
      )}
    </OwnerModal>
  );
};

export default ActionResultModal;
