import { isConsideredMainBooking } from "../../utils";
import { getNow } from "../../utils/dateUtils";
import { CONSULT_STATUS } from "../reducers";
import { getConsultsThunk } from "./consults-actions";
import { getNotificationsUserThunk } from "./user-actions";

// Action types
export const CHANGE_MESSAGE = "CHANGE_MESSAGE";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_ERROR = "SEND_MESSAGE_ERROR";
export const GET_BOOKING = "GET_BOOKING";
export const GET_BOOKING_SUCCESS = "GET_BOOKING_SUCCESS";
export const GET_BOOKING_ERROR = "GET_BOOKING_ERROR";
export const GET_BOOKING_FEED = "GET_BOOKING_FEED";
export const GET_BOOKING_FEED_SUCCESS = "GET_BOOKING_FEED_SUCCESS";
export const GET_BOOKING_FEED_ERROR = "GET_BOOKING_FEED_ERROR";
export const SET_BOOKING_SEEN = "SET_BOOKING_SEEN";
export const SET_BOOKING_HAS_SET_CATEGORIES = "SET_BOOKING_HAS_SET_CATEGORIES";
export const SET_INCOME_BOOKING_CATEGORY = "SET_INCOME_BOOKING_CATEGORY";
export const RESET_CONSULTATION = "RESET_CONSULTATION";
export const SEND_BOOKING_REQUEST = "SEND_BOOKING_REQUEST";
export const SEND_BOOKING_REQUEST_SUCCESS = "SEND_BOOKING_REQUEST_SUCCESS";
export const SEND_BOOKING_REQUEST_ERROR = "SEND_BOOKING_REQUEST_ERROR";
export const ACCEPT_BOOKING = "ACCEPT_BOOKING";
export const ACCEPT_BOOKING_SUCCESS = "ACCEPT_BOOKING_SUCCESS";
export const ACCEPT_BOOKING_ERROR = "ACCEPT_BOOKING_ERROR";
export const RESET_PROCESSING_BOOKING_ACTION = "RESET_PROCESSING_BOOKING_ACTION";
export const REJECT_BOOKING = "REJECT_BOOKING";
export const REJECT_BOOKING_SUCCESS = "REJECT_BOOKING_SUCCESS";
export const REJECT_BOOKING_ERROR = "REJECT_BOOKING_ERROR";
export const SIGN_BOOKING = "SIGN_BOOKING";
export const SIGN_BOOKING_SUCCESS = "SIGN_BOOKING_SUCCESS";
export const SIGN_BOOKING_ERROR = "SIGN_BOOKING_ERROR";
export const SIGN_BOOKING_INIT = "SIGN_BOOKING_INIT";
export const PAY_BOOKING = "PAY_BOOKING";
export const PAY_BOOKING_SUCCESS = "PAY_BOOKING_SUCCESS";
export const PAY_BOOKING_ERROR = "PAY_BOOKING_ERROR";
export const PAY_BOOKING_INIT = "PAY_BOOKING_INIT";
export const GET_BOOKING_OVERLAP = "GET_BOOKING_OVERLAP";
export const GET_BOOKING_OVERLAP_SUCCESS = "GET_BOOKING_OVERLAP_SUCCESS";
export const GET_BOOKING_OVERLAP_ERROR = "GET_BOOKING_OVERLAP_ERROR";
export const CANCEL_BOOKING = "CANCEL_BOOKING";
export const CANCEL_BOOKING_SUCCESS = "CANCEL_BOOKING_SUCCESS";
export const CANCEL_BOOKING_ERROR = "CANCEL_BOOKING_ERROR";
export const SET_BOOKING_PRICE = "SET_BOOKING_PRICE";
export const ATTACH_FILE_TO_BOOKING = "ATTACH_FILE_TO_BOOKING";
export const ATTACH_FILE_TO_BOOKING_SUCCESS = "ATTACH_FILE_TO_BOOKING_SUCCESS";
export const ATTACH_FILE_TO_BOOKING_ERROR = "ATTACH_FILE_TO_BOOKING_ERROR";
export const DETACH_FILE_TO_BOOKING = "DETACH_FILE_TO_BOOKING";
export const DETACH_FILE_TO_BOOKING_SUCCESS = "DETACH_FILE_TO_BOOKING_SUCCESS";
export const DETACH_FILE_TO_BOOKING_ERROR = "DETACH_FILE_TO_BOOKING_ERROR";
export const GET_BOOKING_RATING = "GET_BOOKING_RATING";
export const GET_BOOKING_RATING_SUCCESS = "GET_BOOKING_RATING_SUCCESS";
export const GET_BOOKING_RATING_ERROR = "GET_BOOKING_RATING_ERROR";
export const FOLLOW_BOOKING = "FOLLOW_BOOKING";
export const FOLLOW_BOOKING_SUCCESS = "FOLLOW_BOOKING_SUCCESS";
export const FOLLOW_BOOKING_ERROR = "FOLLOW_BOOKING_ERROR";
export const SET_BOOKING_AD_SPACES = "SET_BOOKING_AD_SPACES";
export const SET_BOOKING_AD_SPACES_SUCCESS = "SET_BOOKING_AD_SPACES_SUCCESS";
export const SET_BOOKING_AD_SPACES_ERROR = "SET_BOOKING_AD_SPACES_ERROR";
export const RESET_BOOKING_AD_SPACES_STATUS = "RESET_BOOKING_AD_SPACES_STATUS";
export const SET_BOOKING_PAYMENT_PLAN = "SET_BOOKING_PAYMENT_PLAN";
export const GET_BOOKING_INVOICES = "GET_BOOKING_INVOICES";
export const GET_BOOKING_INVOICES_SUCCESS = "GET_BOOKING_INVOICES_SUCCESS";
export const GET_BOOKING_INVOICES_ERROR = "GET_BOOKING_INVOICES_ERROR";
export const UPDATE_BOOKING_INVOICE = "UPDATE_BOOKING_INVOICE";
export const DELETE_BOOKING_INVOICE = "DELETE_BOOKING_INVOICE";
export const GET_BOOKING_UNAVAILABLE_DATE_RANGES = "GET_BOOKING_UNAVAILABLE_DATE_RANGES_DATES";
export const GET_BOOKING_UNAVAILABLE_DATE_RANGES_SUCCESS = "GET_BOOKING_UNAVAILABLE_DATE_RANGES_SUCCESS";
export const GET_BOOKING_UNAVAILABLE_DATE_RANGES_ERROR = "GET_BOOKING_UNAVAILABLE_DATE_RANGES_ERROR";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const UPDATE_CONTRACT_DATA_RESET = "UPDATE_CONTRACT_DATA_RESET";
export const UPDATE_CONTRACT_DATA = "UPDATE_CONTRACT_DATA";
export const UPDATE_CONTRACT_DATA_ERROR = "UPDATE_CONTRACT_DATA_ERROR";
export const UPDATE_CONTRACT_DATA_SUCCESS = "UPDATE_CONTRACT_DATA_SUCCESS";
export const MODIFY_LOCAL_CONTRACT_DATA = "MODIFY_LOCAL_CONTRACT_DATA";
export const SET_BOOKING_CONTRACT_METADATA = "SET_BOOKING_CONTRACT_METADATA";
export const SET_BOOKING_SPACE_COMMERCIAL_ACTIVITY = "SET_BOOKING_SPACE_COMMERCIAL_ACTIVITY";
export const GET_BOOKING_CONTRACT = "GET_BOOKING_CONTRACT";
export const GET_BOOKING_CONTRACT_ERROR = "GET_BOOKING_CONTRACT_ERROR";
export const GET_BOOKING_CONTRACT_SUCCESS = "GET_BOOKING_CONTRACT_SUCCESS";
export const SEND_BOOKING_CONTRACT_TO_DOCUSIGN = "SEND_BOOKING_CONTRACT_TO_DOCUSIGN";
export const SEND_BOOKING_CONTRACT_TO_DOCUSIGN_SUCCESS = "SEND_BOOKING_CONTRACT_TO_DOCUSIGN_SUCCESS";
export const SEND_BOOKING_CONTRACT_TO_DOCUSIGN_ERROR = "SEND_BOOKING_CONTRACT_TO_DOCUSIGN_ERROR";
export const RESET_BOOKING_CONTRACT_TO_DOCUSIGN_STATUS = "RESET_BOOKING_CONTRACT_TO_DOCUSIGN_STATUS";
export const REMOVE_BOOKING_CONTRACT_CONTENT = "REMOVE_BOOKING_CONTRACT_CONTENT";
export const UPDATE_BOOKING_CONTRACT = "UPDATE_BOOKING_CONTRACT";
export const UPDATE_BOOKING_CONTRACT_ERROR = "UPDATE_BOOKING_CONTRACT_ERROR";
export const UPDATE_BOOKING_CONTRACT_SUCCESS = "UPDATE_BOOKING_CONTRACT_SUCCESS";
export const REMOVE_BOOKING_GENERATED_CONTRACT = "REMOVE_BOOKING_GENERATED_CONTRACT";
export const GET_BOOKING_CHAIN = "GET_BOOKING_CHAIN";
export const GET_BOOKING_CHAIN_SUCCESS = "GET_BOOKING_CHAIN_SUCCESS";
export const GET_BOOKING_CHAIN_ERROR = "GET_BOOKING_CHAIN_ERROR";
export const SET_TOGGLE_PAYMENT_MODAL = "SET_TOGGLE_PAYMENT_MODAL";
export const PAY_INVOICE = "PAY_INVOICE";
export const PAY_INVOICE_SUCCESS = "PAY_INVOICE_SUCCESS";
export const PAY_INVOICE_ERROR = "PAY_INVOICE_ERROR";
export const PAY_INVOICE_INIT = "PAY_INVOICE_INIT";
export const UPDATE_BOOKING_PARAMS = "UPDATE_BOOKING_PARAMS";

export const changeMessage = (content) => ({
  type: CHANGE_MESSAGE,
  payload: { content },
});

export const sendMessage = () => ({
  type: SEND_MESSAGE,
});

export const sendMessageSuccess = ({ message }) => ({
  type: SEND_MESSAGE_SUCCESS,
  payload: {
    message,
  },
});

export const sendMessageError = (error) => ({
  type: SEND_MESSAGE_ERROR,
  payload: {
    error,
  },
});

export const getBooking = (bookingId) => ({
  type: GET_BOOKING,
  payload: {
    bookingId,
  },
});

export const getBookingSuccess = (data, userId) => ({
  type: GET_BOOKING_SUCCESS,
  payload: {
    data,
    userId,
  },
});

export const getBookingError = (error) => ({
  type: GET_BOOKING_ERROR,
  payload: {
    error,
  },
});

export const getBookingFeed = (bookingId) => ({
  type: GET_BOOKING_FEED,
  payload: {
    bookingId,
  },
});

export const getBookingFeedSuccess = (messages) => ({
  type: GET_BOOKING_FEED_SUCCESS,
  payload: {
    messages,
  },
});

export const getBookingFeedError = (error) => ({
  type: GET_BOOKING_ERROR,
  payload: {
    error,
  },
});

export const setBookingSeen = () => ({
  type: SET_BOOKING_SEEN,
});

export const setBookingHasSetCategories = (id) => ({
  type: SET_BOOKING_HAS_SET_CATEGORIES,
  payload: id,
});

export const setIncomeBookingCategory = (category) => ({
  type: SET_INCOME_BOOKING_CATEGORY,
  payload: category,
});

export const resetConsultation = () => ({
  type: RESET_CONSULTATION,
});

export const rejectBooking = (bookingId, messageId) => ({
  type: REJECT_BOOKING,
  payload: {
    bookingId,
    messageId,
  },
});

export const rejectBookingSuccess = () => ({
  type: REJECT_BOOKING_SUCCESS,
  payload: {},
});

export const rejectBookingError = (error) => ({
  type: REJECT_BOOKING_ERROR,
  payload: {
    error,
  },
});

export const acceptBooking = (bookingId, messageId) => ({
  type: ACCEPT_BOOKING,
  payload: {
    bookingId,
    messageId,
  },
});

export const acceptBookingSuccess = () => ({
  type: ACCEPT_BOOKING_SUCCESS,
  payload: {},
});

export const acceptBookingError = (error) => ({
  type: ACCEPT_BOOKING_ERROR,
  payload: {
    error,
  },
});

export const resetProcessingBookingAction = () => ({
  type: RESET_PROCESSING_BOOKING_ACTION,
});

export const sendBookingRequest = (bookingId) => ({
  type: SEND_BOOKING_REQUEST,
  payload: {
    bookingId,
  },
});

export const sendBookingRequestSuccess = () => ({
  type: SEND_BOOKING_REQUEST_SUCCESS,
  payload: {},
});

export const sendBookingRequestError = (error) => ({
  type: SEND_BOOKING_REQUEST_ERROR,
  payload: {
    error,
  },
});

export const payBookingInit = () => ({
  type: PAY_BOOKING_INIT,
});

export const payBooking = (bookingId) => ({
  type: PAY_BOOKING,
  payload: {
    bookingId,
  },
});

export const payBookingSuccess = () => ({
  type: PAY_BOOKING_SUCCESS,
  payload: {},
});

export const payBookingError = (error) => ({
  type: PAY_BOOKING_ERROR,
  payload: {
    error,
  },
});

export const contractSignGenerateInit = () => ({
  type: SIGN_BOOKING_INIT,
});

export const contractSignGenerate = (bookingId) => ({
  type: SIGN_BOOKING,
  payload: {
    bookingId,
  },
});

export const contractSignGenerateSuccess = () => ({
  type: SIGN_BOOKING_SUCCESS,
  payload: {},
});

export const contractSignGenerateError = (error) => ({
  type: SIGN_BOOKING_ERROR,
  payload: {
    error,
  },
});

export const getBookingOverlap = (bookingId) => ({
  type: GET_BOOKING_OVERLAP,
  payload: {
    bookingId,
  },
});

export const getBookingOverlapSuccess = ({ data }) => ({
  type: GET_BOOKING_OVERLAP_SUCCESS,
  payload: {
    overlaps: data,
  },
});

export const getBookingOverlapError = (error) => ({
  type: GET_BOOKING_OVERLAP_ERROR,
  payload: {
    error,
  },
});

export const cancelBooking = (bookingId) => ({
  type: CANCEL_BOOKING,
  payload: {
    bookingId,
  },
});

export const cancelBookingSuccess = () => ({
  type: CANCEL_BOOKING_SUCCESS,
  payload: {},
});

export const cancelBookingError = (error) => ({
  type: CANCEL_BOOKING_ERROR,
  payload: {
    error,
  },
});

export const setBookingPrice = (price) => ({
  type: SET_BOOKING_PRICE,
  payload: {
    price,
  },
});

export const setBookingPaymentPlan = (paymentPlan) => ({
  type: SET_BOOKING_PAYMENT_PLAN,
  payload: {
    paymentPlan,
  },
});

export const attachFileToBooking = (type) => ({
  type: ATTACH_FILE_TO_BOOKING,
  payload: {
    type,
  },
});

export const attachFileToBookingSuccess = (file, type) => ({
  type: ATTACH_FILE_TO_BOOKING_SUCCESS,
  payload: {
    file,
    type,
  },
});

export const attachFileToBookingError = (error, type) => ({
  type: ATTACH_FILE_TO_BOOKING_ERROR,
  payload: {
    error,
    type,
  },
});

export const detachFileToBooking = (bookingId, fileId) => ({
  type: DETACH_FILE_TO_BOOKING,
  payload: {
    bookingId,
    fileId,
  },
});

export const detachFileToBookingSuccess = (bookingId, fileId) => ({
  type: DETACH_FILE_TO_BOOKING_SUCCESS,
  payload: {
    fileId,
  },
});

export const detachFileToBookingError = (error) => ({
  type: ATTACH_FILE_TO_BOOKING_ERROR,
  payload: {
    error,
  },
});

export const getBookingRating = () => ({
  type: GET_BOOKING_RATING,
});

export const getBookingRatingSuccess = (data) => ({
  type: GET_BOOKING_RATING_SUCCESS,
  payload: data,
});

export const getBookingRatingError = (error) => ({
  type: GET_BOOKING_RATING_ERROR,
  payload: error,
});

export const followBooking = (bookingId) => ({
  type: FOLLOW_BOOKING,
  payload: {
    bookingId,
  },
});

export const followBookingSuccess = () => ({
  type: FOLLOW_BOOKING_SUCCESS,
  payload: {},
});

export const followBookingError = (error) => ({
  type: FOLLOW_BOOKING_ERROR,
  payload: {
    error,
  },
});

export const setBookingAdSpaces = (bookingId) => ({
  type: SET_BOOKING_AD_SPACES,
  payload: {
    bookingId,
  },
});

export const setBookingAdSpacesSuccess = (data) => ({
  type: SET_BOOKING_AD_SPACES_SUCCESS,
  payload: {
    data,
  },
});

export const setBookingAdSpacesError = (error) => ({
  type: SET_BOOKING_AD_SPACES_ERROR,
  payload: {
    error,
  },
});

export const resetBookingAdSpacesStatus = () => ({
  type: RESET_BOOKING_AD_SPACES_STATUS,
});

export const getBookingInvoices = () => ({
  type: GET_BOOKING_INVOICES,
});

export const getBookingInvoicesSuccess = (data) => ({
  type: GET_BOOKING_INVOICES_SUCCESS,
  payload: {
    data,
  },
});

export const getBookingInvoicesError = (error) => ({
  type: GET_BOOKING_INVOICES_ERROR,
  payload: {
    error,
  },
});

export const updateBookingInvoice = (invoice) => ({
  type: UPDATE_BOOKING_INVOICE,
  payload: {
    invoice,
  },
});

export const deleteBookingInvoice = (id) => ({
  type: DELETE_BOOKING_INVOICE,
  payload: {
    id,
  },
});

export const getBookingUnavailableDateRanges = () => ({
  type: GET_BOOKING_UNAVAILABLE_DATE_RANGES,
});

export const getBookingUnavailableDateRangesSuccess = (data) => ({
  type: GET_BOOKING_UNAVAILABLE_DATE_RANGES_SUCCESS,
  payload: data,
});

export const getBookingUnavailableDateRangesError = (error) => ({
  type: GET_BOOKING_UNAVAILABLE_DATE_RANGES_ERROR,
  payload: error,
});

export const updateEvent = (event) => ({
  type: UPDATE_EVENT,
  payload: event,
});

export const modifyLocalContractData = (field, value) => ({
  type: MODIFY_LOCAL_CONTRACT_DATA,
  payload: {
    field,
    value,
  },
});

export const updateContractDataReset = () => ({
  type: UPDATE_CONTRACT_DATA_RESET,
});

export const updateContractData = () => ({
  type: UPDATE_CONTRACT_DATA,
});

export const updateContractDataError = (error) => ({
  type: UPDATE_CONTRACT_DATA_ERROR,
  payload: error,
});

export const updateContractDataSuccess = (contractData) => ({
  type: UPDATE_CONTRACT_DATA_SUCCESS,
  payload: contractData,
});

export const getBookingContract = () => ({
  type: GET_BOOKING_CONTRACT,
});

export const getBookingContractError = (error) => ({
  type: GET_BOOKING_CONTRACT_ERROR,
  payload: error,
});

export const getBookingContractSuccess = (contract) => ({
  type: GET_BOOKING_CONTRACT_SUCCESS,
  payload: contract,
});

export const sendBookingContractToDocusign = () => ({
  type: SEND_BOOKING_CONTRACT_TO_DOCUSIGN,
});

export const sendBookingContractToDocusignError = (error) => ({
  type: SEND_BOOKING_CONTRACT_TO_DOCUSIGN_ERROR,
  payload: error,
});

export const sendBookingContractToDocusignSuccess = () => ({
  type: SEND_BOOKING_CONTRACT_TO_DOCUSIGN_SUCCESS,
});

export const resetBookingContractToDocusignStatus = () => ({
  type: RESET_BOOKING_CONTRACT_TO_DOCUSIGN_STATUS,
});

export const updateBookingContract = (bookingContract) => ({
  type: UPDATE_BOOKING_CONTRACT,
  payload: bookingContract,
});

export const updateBookingContractError = (error) => ({
  type: UPDATE_BOOKING_CONTRACT_ERROR,
  payload: error,
});

export const updateBookingContractSuccess = (contract) => ({
  type: UPDATE_BOOKING_CONTRACT_SUCCESS,
  payload: contract,
});

export const removeBookingContract = () => ({
  type: REMOVE_BOOKING_CONTRACT_CONTENT,
});

export const setBookingContractMetadata = (type, callback) => ({
  type: SET_BOOKING_CONTRACT_METADATA,
  payload: {
    type,
    callback,
  },
});

export const setBookingSpaceCommercialActivity = (data) => ({
  type: SET_BOOKING_SPACE_COMMERCIAL_ACTIVITY,
  payload: data,
});

export const getBookingChain = () => ({
  type: GET_BOOKING_CHAIN,
});

export const getBookingChainSuccess = (data) => ({
  type: GET_BOOKING_CHAIN_SUCCESS,
  payload: data,
});

export const getBookingChainError = (data) => ({
  type: GET_BOOKING_CHAIN_ERROR,
});

export const removeBookingGeneratedContract = () => ({
  type: REMOVE_BOOKING_GENERATED_CONTRACT,
});

export const payInvoiceInit = () => ({
  type: PAY_INVOICE_INIT,
});

export const payInvoice = (invoiceId) => ({
  type: PAY_INVOICE,
  payload: {
    invoiceId,
  },
});

export const payInvoiceSuccess = () => ({
  type: PAY_INVOICE_SUCCESS,
  payload: {},
});

export const payInvoiceError = (error) => ({
  type: PAY_INVOICE_ERROR,
  payload: {
    error,
  },
});

export const updateBookingParams = (params) => ({
  type: UPDATE_BOOKING_PARAMS,
  payload: {
    params,
  },
});

export const getBookingRatingThunk = (bookingId) => {
  return function (dispatch, getState, { api }) {
    const { isAdmin } = getState().user.data;
    const getBookingRateEndpoint = isAdmin ? api.adminGetBookingRate : api.getBookingRate;

    dispatch(getBookingRating());
    return getBookingRateEndpoint(bookingId).then((res) => {
      if (res.message) {
        dispatch(getBookingRatingError(res.message));
      } else {
        dispatch(getBookingRatingSuccess(res.data?.payload));
      }
    });
  };
};

export const sendMessageThunk = (bookingId) => {
  return function (dispatch, getState, { api }) {
    const content = getState().consultation.currentMessage;
    const userId = getState().user.data.id;
    const { avatar } = getState().user.data;
    const message = {
      author_id: userId,
      body: content,
      type: "text",
      author_avatar: avatar,
    };
    const { isAdmin } = getState().user.data;
    const sendMessageEndpoint = isAdmin ? api.adminSendMessage : api.sendMessage;

    dispatch(sendMessage());

    return sendMessageEndpoint(bookingId, content)
      .then(({ status }) => {
        if (status === 200) {
          dispatch(sendMessageSuccess({ message }));
        } else {
          dispatch(sendMessageError());
        }
      })
      .catch(() => dispatch(sendMessageError()));
  };
};

export const getBookingThunk = ({ bookingId }) => {
  return function (dispatch, getState, { api }) {
    dispatch(getBooking(bookingId));
    const userId = getState().user.data.id;
    const { isAdmin } = getState().user.data;
    const getBookingEndpoint = isAdmin ? api.adminGetBooking : api.getBooking;

    return getBookingEndpoint({ bookingId, userId })
      .then(({ data }) => {
        dispatch(getBookingSuccess(data.payload, userId));
      })
      .catch((err) => {
        dispatch(getBookingError(err));
      });
  };
};

export const getBookingFeedThunk = ({ bookingId }) => {
  return function (dispatch, getState, { api }) {
    // this was false at first so it is not real wht is expecting.
    // const wholeChain = getState().consultation.bookingChain.length > 1;
    const wholeChain = true;

    const { isAdmin } = getState().user.data;
    const getBookingFeedEndpoint = isAdmin ? api.adminGetBookingFeed : api.getBookingFeed;

    dispatch(getBookingFeed(bookingId));

    return getBookingFeedEndpoint({ bookingId, wholeChain })
      .then(({ data }) => dispatch(getBookingFeedSuccess(data)))
      .catch((err) => dispatch(getBookingFeedError(err)));
  };
};

export const setBookingSeenThunk = ({ bookingId }) => {
  return function (dispatch, getState, { api }) {
    const userId = getState().user.data.id;
    const { isAdmin } = getState().user.data;
    const setBookingSeenEndpoint = isAdmin ? api.adminSetBookingSeen : api.setBookingSeen;

    dispatch(setBookingSeen());

    return setBookingSeenEndpoint({ bookingId, userId }).then(() => dispatch(getNotificationsUserThunk()));
  };
};

export const rejectBookingThunk = ({ bookingId, messageId }) => {
  return function (dispatch, getState, { api }) {
    dispatch(rejectBooking(bookingId, messageId));
    const userId = getState().user.data.id;

    return api
      .rejectBooking({ bookingId, userId })
      .then(({ data }) => {
        dispatch(rejectBookingSuccess(data));
        dispatch(
          updateEvent({
            id: messageId,
            status: "rejected",
            body: "pending-requested",
          }),
        );
        // TODO: in the future just update with the reducer so there is no need to do new requests
        dispatch(getBookingThunk({ bookingId }));
      })
      .catch((err) => dispatch(rejectBookingError(err)));
  };
};

export const acceptBookingThunk = ({ bookingId, messageId, confirmOn }) => {
  return function (dispatch, getState, { api }) {
    dispatch(acceptBooking(bookingId, messageId));
    const userId = getState().user.data.id;

    return api
      .acceptBooking({ bookingId, userId, confirmOn })
      .then((res) => {
        if (res.data?.success) {
          dispatch(acceptBookingSuccess());
          dispatch(
            updateEvent({
              id: messageId,
              status: "accepted",
              body: "pending-requested",
            }),
          );
          setTimeout(() => {
            dispatch(resetProcessingBookingAction());
          });
          // TODO: in the future just update with the reducer so there is no need to do new requests
          // dispatch(getBookingThunk({ bookingId }));
        } else {
          dispatch(acceptBookingError(res.response.data.message));
        }
      })
      .catch((err) => {
        dispatch(acceptBookingError(err));
      });
  };
};

export const sendBookingContractToDocusignThunk = (bookingContractId) => {
  return function (dispatch, getState, { api }) {
    dispatch(sendBookingContractToDocusign());

    return api
      .sendBookingContractToDocusign(bookingContractId)
      .then((res) => {
        if (res.status === 200) {
          dispatch(sendBookingContractToDocusignSuccess());
        } else {
          dispatch(sendBookingContractToDocusignError(res.response.data.message));
        }
      })
      .catch((err) => dispatch(sendBookingContractToDocusignError(err)));
  };
};

export const sendBookingRequestThunk = ({ bookingId }) => {
  return function (dispatch, getState, { api }) {
    dispatch(sendBookingRequest(bookingId));
    const userId = getState().user.data.id;
    const { isAdmin } = getState().user.data;
    const sendBookingRequestEndpoint = isAdmin ? api.adminSendBookingRequest : api.sendBookingRequest;

    return sendBookingRequestEndpoint({ bookingId, userId }).then((res) => {
      if (res.status === 200) {
        dispatch(sendBookingRequestSuccess(res.data));
        // TODO: in the future just update with the reducer so there is no need to do new requests
        dispatch(getBookingThunk({ bookingId }));
        if (window.location.href.indexOf("chat") < 0) {
          dispatch(getConsultsThunk({ status: CONSULT_STATUS.IN_PROGRESS, index: 1 }));
        }
      } else {
        dispatch(sendBookingRequestError(res.data?.payload?.message));
      }
    });
  };
};

export const contractSignGenerateThunk = ({ bookingId }) => {
  return function (dispatch, getState, { api }) {
    dispatch(contractSignGenerate(bookingId));
    return api.contractSignGenerate({ bookingId }).then((res) => {
      if (res.hasOwnProperty("data")) {
        dispatch(contractSignGenerateSuccess(res.data));
      } else {
        dispatch(contractSignGenerateError(res.response.data?.message));
      }
    });
  };
};

export const getBookingOverlapThunk = ({ bookingId }) => {
  return function (dispatch, getState, { api }) {
    dispatch(getBookingOverlap(bookingId));
    const userId = getState().user.data.id;
    const { isAdmin } = getState().user.data;
    const getBookingOverlapEndpoint = isAdmin ? api.adminGetBookingOverlap : api.getBookingOverlap;

    return getBookingOverlapEndpoint({ bookingId, userId })
      .then(({ data }) => dispatch(getBookingOverlapSuccess(data.payload)))
      .catch((err) => dispatch(getBookingOverlapError(err)));
  };
};

export const cancelBookingThunk = ({ bookingId, isAdmin }) => {
  return function (dispatch, getState, { api }) {
    dispatch(cancelBooking(bookingId));

    const cancelEndpoint = isAdmin ? api.adminCancelBooking : api.cancelBooking;

    return cancelEndpoint({ bookingId })
      .then(({ data }) => {
        if (data) {
          dispatch(cancelBookingSuccess(data));
          // TODO: in the future just update with the reducer so there is no need to do new requests
          dispatch(getBookingFeedThunk({ bookingId }));
          dispatch(getBookingThunk({ bookingId }));
        } else {
          dispatch(cancelBookingError());
        }
      })
      .catch((err) => dispatch(cancelBookingError(err)));
  };
};

export const attachFileToBookingThunk = ({ bookingId, file, type }) => {
  return function (dispatch, getState, { api }) {
    dispatch(attachFileToBooking(type));
    return api
      .attachFileToBooking(bookingId, file)
      .then((res) => {
        if (res.hasOwnProperty("data")) {
          dispatch(
            attachFileToBookingSuccess(
              {
                ...file,
                ...res.data.payload,
                created_at: getNow(),
              },
              type,
            ),
          );
        } else {
          dispatch(attachFileToBookingError(res.response.data.message, type));
        }
      })
      .catch((err) => dispatch(attachFileToBookingError(err, type)));
  };
};

export const detachFileToBookingThunk = ({ bookingId, fileId }) => {
  return function (dispatch, getState, { api }) {
    dispatch(detachFileToBooking(bookingId, fileId));
    return api
      .detachFileToBooking(bookingId, fileId)
      .then((res) => {
        if (res.hasOwnProperty("data")) {
          dispatch(detachFileToBookingSuccess(bookingId, fileId));
        } else {
          dispatch(detachFileToBookingError(res.response.data.message));
        }
      })
      .catch((err) => dispatch(detachFileToBookingError(err)));
  };
};

export const followBookingThunk = ({ bookingId }) => {
  return function (dispatch, getState, { api }) {
    const { isFollower } = getState().consultation;
    const request = isFollower ? api.unfollowBooking : api.followBooking;

    dispatch(followBooking(bookingId));
    return request(bookingId).then((res) => {
      if (res.hasOwnProperty("data")) {
        dispatch(followBookingSuccess(res.data));
      } else {
        dispatch(followBookingError(res.response.data?.message));
      }
    });
  };
};

export const setBookingAdSpacesThunk = ({ bookingId, adSpaces }) => {
  return function (dispatch, getState, { api }) {
    const { isAdmin } = getState().user.data;
    const getBookingDefaultPriceEndpoint = isAdmin ? api.adminGetBookingDefaultPrice : api.getBookingDefaultPrice;

    dispatch(setBookingAdSpaces(bookingId));
    return api
      .setBookingAdSpaces(bookingId, adSpaces)
      .then((res) => {
        if (res.hasOwnProperty("data")) {
          dispatch(setBookingAdSpacesSuccess(adSpaces));
          return getBookingDefaultPriceEndpoint({ bookingId });
        }
        dispatch(setBookingAdSpacesError(res.response?.data?.message));
      })
      .then((res) => res && dispatch(setBookingPrice(res.data?.payload)));
  };
};

export const getBookingInvoicesThunk = (bookingId) => {
  return function (dispatch, getState, { api }) {
    // const wholeChain = getState().consultation.bookingChain.length > 1;
    dispatch(getBookingInvoices());
    const wholeChain = true;
    const getInvoices = api.getBookingInvoicesV2;

    return getInvoices({ bookingId, wholeChain })
      .then((response) => {
        dispatch(getBookingInvoicesSuccess(response.data.payload.data));
      })
      .catch((error) => {
        dispatch(getBookingInvoicesError(error));
      });
  };
};

export function getBookingUnavailableDateRangesThunk(id) {
  return function (dispatch, getState, { api }) {
    const { isAdmin } = getState().user.data;
    const getBookingUnavailableDateRangesEndpoint = isAdmin
      ? api.adminGetBookingUnavailableDateRanges
      : api.getBookingUnavailableDateRanges;

    dispatch(getBookingUnavailableDateRanges(id));
    return getBookingUnavailableDateRangesEndpoint(id)
      .then((response) => dispatch(getBookingUnavailableDateRangesSuccess(response.data.payload)))
      .catch((error) => {
        dispatch(getBookingUnavailableDateRangesError(error));
      });
  };
}

export function getBookingChainThunk(id) {
  return function (dispatch, getState, { api }) {
    const { isAdmin } = getState().user.data;
    const getBookingExtensionChainEndpoint = isAdmin ? api.adminGetBookingExtensionChain : api.getBookingExtensionChain;

    dispatch(getBookingChain());
    return getBookingExtensionChainEndpoint({ bookingId: id })
      .then((response) => {
        const bookingChainList =
          response.data?.payload.length === 1
            ? response.data?.payload
            : [response.data?.payload[0], ...response.data?.payload];
        dispatch(getBookingChainSuccess(bookingChainList));
      })
      .catch((error) => {
        dispatch(getBookingChainError(error));
      });
  };
}

const getTemplateFields = (contractModel, dataOwner) => {
  switch (dataOwner) {
    case "admin":
      return contractModel.adminTemplateFields;
    case "customer":
      return contractModel.customerTemplateFields;
  }
};
export const updateContractDataThunk = (bookingId, dataOwner = "admin") => {
  return function (dispatch, getState, { api }) {
    dispatch(updateContractData());
    const { localContractData } = getState().consultation;
    const { contractModel } = getState().consultation.data;
    const templateFields = getTemplateFields(contractModel, dataOwner);
    const data = templateFields.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.name]: localContractData[curr.name],
      }),
      {},
    );

    return api.updateContractData(bookingId, data, dataOwner).then((res) => {
      if (res.status === 200) {
        dispatch(updateContractDataSuccess(localContractData));
      } else {
        dispatch(updateContractDataError(res.response.data?.message));
      }
    });
  };
};

export const getBookingContractThunk = (bookingId) => {
  return function (dispatch, getState, { api }) {
    const { isAdmin } = getState().user.data;
    const getBookingContractEndpoint = isAdmin ? api.adminGetBookingContract : api.getBookingContract;

    dispatch(getBookingContract());

    return getBookingContractEndpoint(bookingId).then((res) => {
      if (res.status === 200) {
        dispatch(getBookingContractSuccess(res.data.payload));
      } else {
        dispatch(getBookingContractError(res.response.data?.message));
      }
    });
  };
};

export const updateBookingContractThunk = (bookingContract) => {
  return function (dispatch, _, { api }) {
    dispatch(updateBookingContract(bookingContract));
    return api.updateBookingContract(bookingContract).then((res) => {
      if (res.status === 200) {
        dispatch(updateBookingContractSuccess(res.data.payload));
      } else {
        dispatch(updateBookingContractError(res.response.data?.message));
      }
    });
  };
};

export const payInvoiceThunk = ({ invoiceId }) => {
  return function (dispatch) {
    dispatch(payInvoice(invoiceId));
    dispatch(payInvoiceSuccess({}));
  };
};
